import React from 'react';
import styled from 'styled-components';

export const InfoSection = ({ data }) => (
  <InfoSectionStyled>
    {data.sectionHeading && (
      <>{data.sectionHeading && <h3>{data.sectionHeading}</h3>}</>
    )}
    <Amenities columns={2}>
      {data.section && data.section.length > 0 && (
        <>
          {data.section.map(section => (
            <Section data={section} key={section._key} />
          ))}
        </>
      )}
    </Amenities>
  </InfoSectionStyled>
);

const Section = ({ data }) => (
  <>
    {data._type === 'labelAndField' ? (
      <GridItem data={data} />
    ) : (
      <>
        {data.sectionHeading && (
          <div className="subHeading">
            <h4>{data.sectionHeading}</h4>
          </div>
        )}
        {data.microSection && data.microSection.length && (
          <MicroSection data={data.microSection} />
        )}
      </>
    )}
  </>
);

const MicroSection = ({ data }) => (
  <>
    {data.map(item => (
      <GridItem data={item} key={item._key} />
    ))}
  </>
);

const GridItem = ({ data }) => (
  <div className="item">
    <span>{data.fieldLabel}: </span>
    <span>{data.fieldInput}</span>
  </div>
);

const InfoSectionStyled = styled.div`
  padding: 30px 0;
  h3 {
    font-weight: ${props => props.theme.font_weight.bold};
    border-bottom: 1px solid ${props => props.theme.color.black_light};
    ${props => props.theme.font_size.larger};
    color: ${props => props.theme.color.primary};
  }
`;

const Amenities = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  grid-gap: 20px 10px;
  margin-top: 30px;

  div.item {
    /* display: flex;
    flex-direction: column; */
    margin-left: 10px;

    span:first-child {
      font-weight: ${props => props.theme.font_weight.bold};
      color: ${props => props.theme.color.font.regular};
      margin-bottom: 15px;
    }
  }

  div.subHeading {
    grid-column: 1/-1;

    h4 {
      color: ${props => props.theme.color.black};
      ${props => props.theme.font_size.large};
      line-height: 21px;
      margin: 0;
    }
  }
`;
