import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import PropertyTemplate from '../components/PropertyForSaleTemplate';

export const query = graphql`
  query($id: String!) {
    sanityProperties(id: { eq: $id }) {
      address
      informationSections {
        sectionHeading
        _key
        _type
        section {
          ... on SanityLabelAndField {
            _key
            _type
            fieldInput
            fieldLabel
          }
          ... on SanityMicroSection {
            _key
            _type
            sectionHeading
            microSection {
              fieldInput
              fieldLabel
              _type
              _key
            }
          }
        }
      }
      bathrooms
      bedrooms
      city
      id
      images {
        image {
          _key
          caption
          alt
          asset {
            url
            fluid(maxWidth: 600) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      _rawDescription
      nickname
      price
      sqft
      state
      underContract
      zip
    }
  }
`;

const Property = (props) => <PropertyTemplate data={props} />;

export default Property;
