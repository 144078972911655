import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

const CTA1 = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      sanitySitewideSettings {
        bgImage {
          asset {
            fluid(maxWidth: 1400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `);

  return (
    <CTA1Wrap>
      <CTAImg
        fluid={data.sanitySitewideSettings.bgImage.asset.fluid}
        alt={data.sanitySitewideSettings.bgImage.alt}
      />
      <Text>{children}</Text>
    </CTA1Wrap>
  );
};

const CTAImg = styled(Img)`
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  mix-blend-mode: multiply;

  img {
    filter: saturate(0%) contrast(120%);
    opacity: 0.5 !important;
  }
`;

const CTA1Wrap = styled.section`
  margin-top: 50px;
  padding: 40px;
  background-color: ${props => props.theme.color.secondary};
  position: relative;
  display: flex;
  justify-content: center;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.2);

  @media (min-width: ${props => props.theme.screen.md}) {
    padding: 120px 100px;
  }
`;

const Text = styled.div`
  max-width: 690px;
  text-align: center;
  z-index: 2;

  &,
  p,
  a {
    color: ${props => props.theme.color.white};
    font-size: 25px;
    font-family: ${props => props.theme.font.primary};
    font-weight: ${props => props.theme.font_weight.regular};
    line-height: 33px;

    @media (min-width: ${props => props.theme.screen.lg}) {
      font-size: 39px;
      line-height: 48px;
    }
  }

  a {
    font-weight: ${props => props.theme.font_weight.bold};

    &:hover {
      color: ${props => props.theme.color.white};
    }
  }
`;

export default CTA1;
